import { useEffect, useRef, useState } from "react"
import gsap from "gsap";
import React from "react";
import "./animate-product-card.css";
import useOnScreen from "./on-screen"


const AnimateProductCard = ({children, className, duration=1, stagger=0.08, delay=0, transitionStatus}: {children: React.ReactNode, className: string, duration?: number, stagger?: number, delay?: number, transitionStatus: string}) =>  {

    const [revealed, setRevealed] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const reveal = useOnScreen(ref, 0);


    useEffect(() => {
        if (reveal && !revealed) {
            let cards = document.querySelectorAll(`.${className} [data-card-animate="true"]`);
            setTimeout(() => {
                gsap.to(cards, {
                    duration: duration,
                    transform: "translate3d(0px, 0%, 0px)",
                    opacity: 1,
                    ease: "power4",
                    stagger: stagger
                });
            }, delay);
            setRevealed(true);
        }

    }, [reveal]);

    useEffect(() => {
        let cards = document.querySelectorAll(`.${className} [data-card-animate="true"]`);

        if (transitionStatus === 'entering') {
            if (reveal) {
                setTimeout(() => {
                    gsap.to(cards, {
                        duration: duration,
                        transform: "translate3d(0px, 0%, 0px)",
                        opacity: 1,
                        ease: "power4",
                        stagger: stagger
                    });
                }, delay)
            }
        }
        if (transitionStatus === 'exiting') {
            if (cards) {
                gsap.to(cards, {
                    duration: 0.3,
                    opacity: 0,
                    ease: "power4",
                    y: 20,
                    stagger: 0
                });
            }
        }
    }, [transitionStatus]);

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    )

}

export default AnimateProductCard;