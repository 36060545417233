import React from "react"

const ProductTagPill = ({background="white", color="black", shadow = "", children}: {background: string, color: string, shadow: string, children: React.ReactNode}) => {

    return (
        <div className={"flex items-center absolute top-0 right-0 rounded-bl-2xl px-4 h-8 text-sm font-display font-bold"} style={{ background: background, color: color, lineHeight: 1, boxShadow: shadow }}>
            <p className={"transform -translate-y-[1px]"}>{children}</p>
        </div>
    )

}

export default ProductTagPill;