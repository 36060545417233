import React, { useEffect } from "react"
import {ProductItem} from "../../models/product-item"
import PageLink from "../../models/page-link"
import SEO from "../../components/seo/seo"
import AnimateTexts from "../../components/animations/animate-texts"
import CollectionBackground from "./collection-background"
import CustomContainer from "../../components/general/contianer"
import ProductCard from "../../components/product-display/product-card/product-card"
import AnimateProductCard from "../../components/animations/animate-product-card"
import Footer from "../../components/footer/footer"
import { Item, logEvent } from "firebase/analytics"
import { analytics } from "../../utils/firebase"
import ReactPixel from "react-facebook-pixel"

const CollectionsTemplate = ({ pageContext: { collections: collectionsContext, products: productsContext }, transitionStatus }) => {

    let collections: PageLink = new PageLink(collectionsContext);
    let products: ProductItem[] = productsContext?.map((el) => new ProductItem(el)) ?? [];
    let identifier = collections.slug.replace("/", "");

    // Analytics
    useEffect(() => {
        let items: Item[] = [];
        products.forEach((p) => {
            items.push(p.toDataLayer());
        })
        logEvent(analytics, 'view_item_list',
            {
                items: items,
                item_list_id: identifier,
                item_list_name: collections.title
            })
        ReactPixel.track('PageView');
        let _learnq = window["_learnq"] || [];
        _learnq.push(["track", "Viewed Category",{
            "CategoryName": collections.title,
            "CategoryID": collections.slug.replace("/", ""),
            "ImageUrl": collections.imageKitLink,
            "Url": `https://groomd.co.in/collections${collections.slug}`
        }]);
    }, []);

    return <>
        <SEO description={collections.seo.description} title={collections.seo.title} keywords={collections.seo.keywords} pathname={"/collections/"+identifier}/>
        <CollectionBackground transitionStatus={transitionStatus} collections={collections}/>
        <section className={"min-h-screen"}>
            <CustomContainer>
                {/*<div className={"my-6"}>*/}
                {/*    <AnimateTexts className={`collection_text_${identifier}`} transitionStatus={transitionStatus} delay={400}>*/}
                {/*        <h1 className={"font-extrabold text-4xl leading-relaxed sm:text-6xl sm:leading-normal lg:text-7xl font-sans lg:leading-snug"} data-text-animate={true}>*/}
                {/*            {collections.title}*/}
                {/*        </h1>*/}
                {/*        <p data-text-animate={true} className={"font-medium text-lg sm:text-xl lg:text-2xl font-sans max-w-sm opacity-80 mt-0 sm:mt-2"}>*/}
                {/*            {collections.subtitle}*/}
                {/*        </p>*/}
                {/*    </AnimateTexts>*/}
                {/*</div>*/}
                <div className={"my-6 mt-6"}>
                    <AnimateProductCard className={`collection_products_${identifier}`} transitionStatus={transitionStatus} delay={500}>
                        <ul className={"grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-6 xl:gap-8"}>
                            {
                                products.map((product) => {
                                    return <li key={product.id} data-card-animate={true}>
                                        <ProductCard product={product}  />
                                    </li>
                                })
                            }
                        </ul>
                    </AnimateProductCard>
                </div>
            </CustomContainer>
        </section>
        <div className={"h-8"}/>
        <Footer transitionStatus={transitionStatus}/>
    </>
}

export default CollectionsTemplate;
