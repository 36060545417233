import React from "react"
import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaRegStar } from "@react-icons/all-files/fa/FaRegStar"
import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt"

const StarRating = ({averageReview, totalReviews, textClassName="", onClick, size = 12, color = "rgb(218,165,65)"}: {averageReview: number, totalReviews: number, textClassName?:string, onClick?:() => void, size?: number, color?: string}) => {

    return (
        <div className={"flex items-center group relative cursor-pointer"} onClick={() => { if(onClick) onClick(); }}>
            {[0,1,2,3,4].map((value) => {
                if (averageReview >= (value+1))
                    return <FaStar key={value} color={color} style={{ marginRight: "4px" }} size={size}/>
                else if (averageReview <= value)
                    return <FaRegStar key={value} color={color} style={{ marginRight: "4px" }} size={size}/>
                else
                    return <FaStarHalfAlt key={value} color={color} style={{ marginRight: "4px" }} size={size}/>
            })}
            {totalReviews > 0 &&
                <button onClick={onClick} className={`ml-1 text-xs opacity-100 font-normal ${textClassName} transform translate-y-px font-medium font-display`}>
                  {totalReviews}{textClassName ? ` Review${totalReviews === 1 ? "" : "s"}` : ""}
                </button>
            }
            <div className={"h-px w-0 w-full absolute -bottom-1.5 opacity-0 group-hover:opacity-100 transition duration-300"} style={{ background: "rgb(218,165,65" }}/>
        </div>
    )

}

export default StarRating;