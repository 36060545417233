import React  from "react"
import { ProductItem } from "../../../models/product-item"
import StarRating from "./star-rating"
import ProductCardQuickAdd from "./product-card-quick-add"
import CustomTransitionLink from "../../transition-wrapper/custom-transition-link"

const ProductCard = ({product}: {product: ProductItem}) => {

    return (
        <div className={"relative w-full h-full flex flex-col rounded-2xl overflow-hidden border-2 border-background-dark hover:border-background-light transition duration-300 hover:shadow-special"}>
            <CustomTransitionLink to={"/products/" + product.urlString} className={"relative w-full aspect-h-6 aspect-w-5"}>
                {/*Product Image*/}
                <div className={"absolute w-auto h-auto"} style={{ background: `#e7e8e3`, }}>
                    <img className={`w-full h-full absolute left-0 top-0 ${product.category.includes("routines") ? "object-contain" : "object-cover"}`} src={product.catalogue_images.imageKitLink("default")} alt={product.title} loading={"lazy"}/>
                </div>

                {/*Product Hover Image*/}
                {/*<div className={"absolute w-full h-full top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-200"} style={{ background: `rgba(231,232,227,0.1)` }}>*/}
                {/*    <img className={"w-full h-full absolute left-0 top-0 object-cover"} src={product.catalogue_images.imageKitLink("hover")} alt={product.title} loading={"lazy"}/>*/}
                {/*</div>*/}
            </CustomTransitionLink>
            <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 sm:px-6 sm:pb-6 h-full flex flex-col"}>
                <CustomTransitionLink to={"/products/" + product.urlString}>
                    {/*Product Title*/}
                    <div className={"mb-px flex"}>
                        <StarRating color={"rgb(234,180,49)"} size={14} averageReview={product.averageReview} totalReviews={0}/>
                        <div className={'bg-white/30 rounded-full px-2.5 pb-[3px] py-[2px] text-yellow-600 ml-2 text-xs font-display tracking-wide'}>
                            {product.totalReviews} Reviews
                        </div>
                    </div>
                    <p className={"font-display font-medium text-2xl"}>
                        {product.title}
                    </p>
                    <p className={"font-sans text-base mt-1 mr-2 opacity-70"}>
                        {product.tagline}
                    </p>
                </CustomTransitionLink>
                <div className={"pt-4 sm:pt-5 mt-auto"}>
                    <ProductCardQuickAdd product={product}/>
                </div>
            </div>
        </div>
    )

}

export default ProductCard;