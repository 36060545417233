import ProductTagPill from "./product-tag-pill"
import React, { useState } from "react"
import { ProductItem } from "../../../models/product-item"
import { addItem } from "../../../redux/actions/cart/cart-actions"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { CartActionTypes } from "../../../redux/types/cart-type"
import { createCurrencyText } from "../../../utils/fomatters"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { FiMail } from "@react-icons/all-files/fi/FiMail"
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart"

const ProductCardQuickAdd = ({product, small = false}: {product: ProductItem, small?: boolean}) => {

    const [variationSelected, setVariationSelected] = useState("");
    const [error, setError] = useState("");
    const [added, setAdded] = useState(false);
    const dispatch = useDispatch<Dispatch<CartActionTypes>>();

    let currentMrp: number = (product.variation.find((v) => v.tag === variationSelected) ?? product.lowestVariation).mrp;
    let currentPrice: number = (product.variation.find((v) => v.tag === variationSelected) ?? product.lowestVariation).price;

    return (
        <>
            {error &&
            <p className={"text-sm font-sans text-red-700"}>
                {error}
            </p>
            }
            <div className={"flex flex-wrap items-start w-full mb-1 mt-1"}>
                {product.variation.length > 1 &&
                product.variation.map((v) => {
                    return <button key={v.tag}
                                   className={`transition duration-200 font-medium font-sans text-sm px-4 py-1 mr-3 mb-2 border rounded-xl border-gray-300 ${v.tag === variationSelected ? "bg-primary-default text-white" : "text-gray-800 bg-background-default hover:bg-background-light hover:text-black"}`}
                                   onClick={() => { setVariationSelected(v.tag); setError("") }}
                    >
                        {v.title}
                        {v.mrp !== v.price && (
                            <span className={`ml-3 opacity-70 ${v.tag === variationSelected ? "text-green-300" : "text-green-600"}`}>{((v.mrp - v.price)/v.mrp * 100).toFixed(0)}% off</span>
                        )}
                    </button>
                })
                }
            </div>
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    if (product.out_of_stock) {

                    } else {
                        if (product.variation.length === 1) {
                            dispatch(addItem(product, (product.lowestVariation.tag)));
                            setAdded(true);
                            setTimeout(() => {
                                document.getElementById("cart_dialog_button").click()
                            }, 150)
                        } else {
                            if (variationSelected) {
                                dispatch(addItem(product, (product.variation.find((v) => v.tag === variationSelected)?.tag ?? product.lowestVariation.tag)))
                                setAdded(true);
                                setTimeout(() => {
                                    document.getElementById("cart_dialog_button").click()
                                }, 150)
                            } else {
                                setError("Please select a size.")
                            }
                        }
                        setTimeout(() => {
                            setAdded(false);
                        }, 750)
                    }
                }}
                className={"group relative overflow-hidden w-full px-4 py-2.5 transition duration-300 rounded-xl border-2 border-background-dark hover:border-gray-600 hover:shadow-inner hover:text-primary-light flex justify-between bg-background-light"}>
                <span className={`flex items-center justify-center bg-green-800 w-full h-full absolute rounded-lg left-0 top-0 space-x-2 transition duration-200 ${added ? "opacity-100" : "opacity-0 pointer-events-none"}`} style={{ zIndex: 1 }}>
                    <FiCheck size={14} strokeWidth={3} className={"text-white"}/>
                    <span className={"font-sans text-white font-medium text-sm"}>
                        Added
                    </span>
                </span>
                {
                    product.out_of_stock ?
                        <span className={"flex flex-row-reverse sm:flex-row items-center justify-between w-full"}>
                            <span className={"font-sans font-medium text-sm"}>
                                Notify <span className={"hidden lg:inline-flex"}>when available</span>
                            </span>
                            <FiMail size={14}/>
                        </span>
                        :
                        <span className={"flex items-center justify-between w-full"}>
                            <span className={"font-sans font-medium text-sm"}>
                                Add <span className={`${small ? "hidden xl:inline" : ""}`}>to Cart</span>
                            </span>
                            <span className={"font-sans font-bold text-sm"}>
                                {createCurrencyText(currentPrice, {})}
                                {!variationSelected && product.variation.length > 1 &&
                                "+"
                                }
                                {(variationSelected || product.variation.length == 1) && currentPrice < currentMrp &&
                                <span className={"line-through pl-2 opacity-60 font-medium"}>
                                    {createCurrencyText(currentMrp, {})}
                                </span>
                                }
                            </span>
                        </span>
                }
            </button>
            {
                (() => {
                    if (product.out_of_stock) {
                        return <ProductTagPill background={"rgb(37,37,37)"} color={"white"}>
                            Out of stock
                        </ProductTagPill>
                    } else if (product.tags.includes("new")) {
                        return <ProductTagPill background={"white"} color={"rgb(8,32,59)"} shadow={"0 4px 14px -6px rgba(50,50,150,0.4)"}>
                            NEW
                        </ProductTagPill>
                    } else if (product.variation.length == 1 && currentPrice < currentMrp) {
                        return <ProductTagPill background={"rgba(173,12,50,0.9)"} color={"white"} shadow={"0 4px 14px -6px rgba(255,50,50,0.6)"}>
                            {((currentMrp - currentPrice)/currentMrp * 100).toFixed(0)}% OFF
                        </ProductTagPill>
                    } else if (product.variation.length != 1 && variationSelected && currentPrice < currentMrp) {
                        return <ProductTagPill background={"rgb(173,12,50)"} color={"white"} shadow={"0 4px 14px -6px rgba(255,50,50,0.6)"}>
                            {((currentMrp - currentPrice)/currentMrp * 100).toFixed(0)}% OFF
                        </ProductTagPill>
                    }
                })()
            }
        </>
    )

}

export default ProductCardQuickAdd;