import React, { useEffect } from "react"
import gsap from "gsap"
import "./collections.css";
import PageLink from "../../models/page-link"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CustomContainer from "../../components/general/contianer"
import AnimateTexts from "../../components/animations/animate-texts"

gsap.registerPlugin(ScrollTrigger);

const CollectionBackground = ({transitionStatus, collections}: {transitionStatus: string, collections: PageLink}) => {

    let identifier = collections.slug.replace("/", "");

    // First Load Transition
    useEffect(() => {
        let section = document.querySelector(`.collection_image_${identifier}`);
        let bg: HTMLDivElement = section.querySelector(`.bg`);

        gsap.to(bg, {
            opacity: 1,
            scale: 1,
            ease: "sine",
            delay: 0.4,
            duration: 0.8
        });

        gsap.to(section, {
            opacity: 1,
            duration: 0.8
        });

    }, []);

    // Enter-Exit Transitions
    useEffect(() => {
        let section = document.querySelector(`.collection_image_${identifier}`);
        let bg: HTMLDivElement = section.querySelector(`.bg`);

        if (transitionStatus === 'entering') {
            gsap.to(bg, {
                opacity: 1,
                scale: 1,
                ease: "sine",
                delay: 0.4,
                duration: 0.8
            });
            gsap.to(section, {
                opacity: 1,
                duration: 0.8
            });
        }
        if (transitionStatus === 'exiting') {
            gsap.to(bg, {
                opacity: 0,
                duration: 0.3
            });
            gsap.to(section, {
                opacity: 0,
                duration: 0.3
            });
        }
    }, [transitionStatus]);

    return (
        <section className={`collection_image_${identifier} bg_section overflow-hidden`} style={{ willChange: "transform" }}>
            <CustomContainer style={{ marginTop: "136px" }}>
                <div className={"bg_container relative overflow-hidden"} style={{ borderRadius: "24px"}}>
                    <div className={'bg'} style={{
                        transformOrigin: "center",
                        willChange: "transform",
                        width: "100%",
                        borderRadius: "24px",
                        backgroundColor: `rgb(239,237,224)`,
                        backgroundImage: `url(${collections.imageKitLink})`,
                        backgroundSize: "cover",
                        backgroundPosition: `center center`,
                        backgroundRepeat: "no-repeat"
                    }}>
                        <div className={" top-0 w-full h-full bg-gradient-to-t from-black/20 z-10 absolute sm:hidden"} style={{ borderRadius: "24px" }}/>
                    </div>
                    <div className={"absolute top-0 h-full flex w-[90%] items-end z-20"}>
                        <AnimateTexts className={`collection_text_${identifier}`} transitionStatus={transitionStatus} delay={400}>
                            <h1 className={"ml-6 sm:ml-8 font-extrabold text-4xl leading-relaxed sm:text-6xl sm:leading-normal lg:text-7xl font-display lg:leading-snug text-white"} data-text-animate={true}>
                                {collections.title}
                            </h1>
                            <p data-text-animate={true} className={"ml-6 sm:ml-8 mb-6 sm:mb-8 font-medium text-lg sm:text-xl lg:text-2xl font-display max-w-sm opacity-80 mt-0 sm:mt-2 text-white"}>
                                {collections.subtitle}
                            </p>
                        </AnimateTexts>
                    </div>
                </div>
            </CustomContainer>
        </section>
    )

}

export default CollectionBackground;