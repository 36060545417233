import {
    ADD_DISCOUNT,
    ADD_ITEM,
    ADD_MEMBER_POINTS,
    CartActionTypes, CartState,
    DELETE_ITEM,
    REMOVE_DISCOUNT,
    REMOVE_MEMBER_POINTS,
    SET_CART, UPDATE_DELIVERY,
    UPDATE_PAYMENT,
    UPDATE_SHIPPING
} from "../../types/cart-type"
import { CartItem, ProductItem } from "../../../models/product-item"
import Discount from "../../../models/discount"
import ReactPixel from "react-facebook-pixel"
import Address from "../../../models/address"
import CreditCard from "../../../models/credit-card"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../utils/firebase"

export function addItem(item: ProductItem, variation: string): CartActionTypes {

    let product = new CartItem(item.toJson());
    product.variationId = variation;
    product.quantity = 1;

    let p = product.toDataLayer();
    logEvent(analytics,"add_to_cart", {
        items: [p]
    });

    ReactPixel.track('AddToCart', item.toFacebookEvent())

    return {
        type: ADD_ITEM,
        payload: {
            product: item,
            variation: variation
        }
    }
}

export function deleteItem(item: ProductItem, variation: string, clear?: boolean): CartActionTypes {

    let product = new CartItem(item.toJson());
    product.variationId = variation;
    product.quantity = 1;

    let p = product.toDataLayer();
    logEvent(analytics,"remove_from_cart", {
        items: [p]
    });

    return {
        type: DELETE_ITEM,
        payload: {
            product: item,
            variation: variation,
            clear: clear ?? false
        }
    }
}

export function addDiscount(discount: Discount): CartActionTypes {
    return {
        type: ADD_DISCOUNT,
        payload: {
            discount: discount
        }
    }
}

export function removeDiscount(discount: Discount): CartActionTypes {
    return {
        type: REMOVE_DISCOUNT,
        payload: {
            discount: discount
        }
    }
}

export function addMemberDiscount(discount: number): CartActionTypes {
    return {
        type: ADD_MEMBER_POINTS,
        payload: {
            memberPoints: discount
        }
    }
}

export function removeMemberDiscount(discount: number): CartActionTypes {
    return {
        type: REMOVE_MEMBER_POINTS,
        payload: {
            memberPoints: discount
        }
    }
}

export function updatePayment(params: { method: string, methodInstrument: string, card: CreditCard }): CartActionTypes {
    return {
        type: UPDATE_PAYMENT,
        payload: {
            ...params
        }
    }
}

export function updateShipping(address: Address): CartActionTypes {
    return {
        type: UPDATE_SHIPPING,
        payload: {
            address: address
        }
    }
}

export function updateDelivery(delivery_type: string): CartActionTypes {
    return {
        type: UPDATE_DELIVERY,
        payload: {
            delivery_type: delivery_type
        }
    }
}

export function setCart(cartState: CartState): CartActionTypes {
    return {
        type: SET_CART,
        payload: {
            cart: cartState.items,
            uid: cartState.uid,
            discount: cartState.discount,
            memberPoints: cartState.memberDiscount,
            address: cartState.address,
            method: cartState.method,
            methodInstrument: cartState.methodInstrument,
            card: cartState.card,
            delivery_type: cartState.delivery_type
        }
    }
}